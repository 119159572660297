<script lang="ts" setup>
import type { News } from '~~/layers/api/models/Posts'

interface Props {
    items: News[];
}

const props = defineProps<Props>()

const { toNews } = useRouteBuilder()
</script>

<template>
    <div
        class="snap-x flex flex-nowrap overflow-x-scroll overscroll-x-none scrollbar-none gap-4 pb-4 lg:pb-0"
    >
        <NuxtLink
            v-for="item in props.items"
            :key="item.slug"
            :to="toNews(item)"
        >
            <NewsHorizontalListItem :item="item" />
        </NuxtLink>
    </div>
</template>

<style scoped></style>
